.offer-offer {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.offer-image {
  object-fit: cover;
}
.offer-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  flex-direction: column;
}
.offer-details {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.offer-text {
  font-size: 18px;
  font-weight: 700;
  color: #0f1a54;
}
.offer-text1 {
  font-size: 16px;
  color: #000;
}
.offer-text2 {
  color: #fff;
  font-size: 16px;
}
.home-offers a {
  text-decoration: none;
}

@media(max-width: 991px) {
  .offer-image {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .offer-details {
    align-items: center;
  }
  .offer-text2 {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
  }
}